#portfolio {
    margin-bottom: 30px;
    text-align: center;
  }
  
  #portfolio #projectContainer {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .project {
    width: 25%;
    padding-top: 15%;
    background-size: cover;
    color: #aec3d6;
    text-align: left;
    filter: brightness(0.5);
    transition-property: filter;
    transition-duration: 0.3s;
    text-shadow: 2px 2px black;
    background-position: center;
  }
  
  .project:hover {
    filter: brightness(1);
  }
  
  .project h3 {
    font-size: 1.5rem;
    padding-left: 10px;
  }
  
  @media screen and (max-width: 800px) {
    #portfolio .flex {
        flex-direction: column;
    }
    .project {
        width: 100%;
        padding-top: 50%;
    }
  }
  
  #portfolio #gameDev {
    background-image: url("../../images/WEBP/RiverSunsetSmall.webp");
  }
  
  #portfolio #thisWebsite {
    background-image: url("../../images/WEBP/ThisWebsite.webp");
  }
  
  #portfolio #music {
    background-image: url("../../images/WEBP/LJSIcon.webp");
  }
  
  #portfolio #newsletter {
    background-image: url("../../images/WEBP/Substack.webp");
  }
  
  #portfolio #koineClothing {
    background-image: url("../../images/WEBP/KoineClothing.webp");
  }
  
  #portfolio #marketing {
    background-image: url("../../images/WEBP/Marketing.webp");
  }
  
  #portfolio #podcast {
    background-image: url("../../images/WEBP/TechnicallsLogo.webp");
  }
  
  #portfolio #art {
    background-image: url("../../images/WEBP/NMEIcon3x.webp");
  }
  
  #portfolio #art h3 {
    color: #0b1424;
    text-shadow: none;
  }